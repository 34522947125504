const $window = $(window);
const canvas = document.getElementById("dots");
const ctx = canvas.getContext("2d");
const _pts = [];
const _pre_sec = 0;
var w = 100;
var h = 100;
var pt;
var t;
var _targets;
const INITIALIZE_ANIMATION_WIDTH = 959;

const COLOR = {
    PURPLE : 'rgb(104, 0, 255)',
    RED    : 'rgb(255, 44, 52)',
    BLACK  : 'rgb(34, 34, 34)',
    WHITE  : 'rgb(255, 255, 255)',
};

var colorParticles = COLOR.RED;
var colorBackground = COLOR.BLACK;

const requestAnimFrame = (() => {
    return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        }
    );
})();

function Target(rad) {
    var incr = 0.9;
    this.x = 0;
    this.y = 0;
    this.rotate = 0;
    this.radius = rad;
    this.rotate_speed = 0.001 * 0.1 + 0.001;
    this.friction = 0.01 * 0.8 + 0.1;
    this.speed = 0.01 * 0.2 + 0.03;
    this.step = 5 * 0.5 + 0.0001;

    this.freq = 0.0001 * 0.09 + 0.01;
    this.bold_rate = 1 * 0.3 + 0.1;
}

function VPoint(x, y) {
    this.x = x;
    this.y = y;
    this.vx = 0;
    this.vy = 0;
    this.target = null;
}

function resizeCanvas() {
    canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;
    canvas.height = $('.sec01').innerHeight();
    w = canvas.width;
    h = canvas.height;
    refreshTarget();
}

function refreshTarget() {
    _targets = [];
    t = new Target(w / 10);

    t.x = w * .65;
    t.y = h * .50;
    _targets.push(t);
    
    var l = _pts.length;
    for (i = 0; i < l; i++) {
        _pts[i].target = _targets[i % _targets.length];
    }
}

function update() {
    var i = 0;
    var l = _targets.length;
    var t;
    var pt;

    for (i = 0; i < l; i++) {
        t = _targets[i];
        t.rotate += t.rotate_speed;
    }

    l = _pts.length;

    ctx.fillStyle = colorBackground;
    ctx.fillRect(0, 0, w * 2, h * 2);

    ctx.fillStyle = colorParticles;
    // 104, 0, 255
    for (i = 0; i < l; i++) {
        pt = _pts[i];
        t = pt.target;
        var t_radius =
        Math.cos(t.rotate * 2.321 + t.freq * i) * t.radius * t.bold_rate +
        t.radius;
        var tx = t.x + Math.cos(t.rotate + t.step * i) * t_radius;
        var ty = t.y + Math.sin(t.rotate + t.step * i) * t_radius;

        pt.vx += (tx - pt.x) * t.speed;
        pt.vy += (ty - pt.y) * t.speed;

        pt.x += pt.vx;
        pt.y += pt.vy;

        pt.vx *= t.friction;
        pt.vy *= t.friction;

        if (pt.x >= 0 && pt.x <= w && pt.y >= 0 && pt.y <= h) {
            ctx.fillRect(pt.x, pt.y, 2, 2);
        }
    }
    requestAnimFrame(update);
}

for (var i = 0; i < 2500; i++) {
    pt = new VPoint(
        Math.random(1) * window.innerWidth,
        Math.random(1) * window.innerHeight
    );
    _pts.push(pt);
}


if ($window.width() > INITIALIZE_ANIMATION_WIDTH) {
    resizeCanvas();
    
    window.addEventListener("resize", resizeCanvas, false);
    
    update();
} else {
    refreshTarget();
}

// var globalScrollStage = 1;
// var lastScrollEventTimeStamp = 0;
// var supportsPassive = false;
// var wheelOpt = supportsPassive ? { passive: false } : false;
// var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// var keys = {37: 1, 38: 1, 39: 1, 40: 1};

// function initializeListeners() {
//     window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
//     window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
//     window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
//     window.addEventListener('keydown', preventDefaultForScrollKeys, false);
// }

function getScrollTop() {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

// var preventDefault = function preventDefault(e, type = 'scroll') {
//     if ($window.width() <= INITIALIZE_ANIMATION_WIDTH) {
//         return;
//     }
//     var localScrollStage = globalScrollStage;
//     if (globalScrollStage === 1 || globalScrollStage === 2) {
//         e.preventDefault();
//     }

//     if (type === 'keypress') {
//         if ((e.key === 'ArrowDown' || e.code === 'ArrowDown') && (globalScrollStage === 1 || globalScrollStage === 2)) {
//             localScrollStage = globalScrollStage + 1;
//         }
//         if ((e.key === 'ArrowUp' || e.code === 'ArrowUp') && (getScrollTop() === 0)) {
//             localScrollStage = globalScrollStage - 1;
//             type = 'keypress';
//         }

//         if (localScrollStage < 1) {
//             localScrollStage =  1;
//         }
//         if (localScrollStage > 3) {
//             localScrollStage = 3;
//         }
    
//         if (getScrollTop() === 0) {
//             trigger('change-to-section', {
//                 stage: localScrollStage,
//                 type: type
//             })
//         }
//     } else {//  Scroll
//         if (e.deltaY > 0 && (globalScrollStage === 1 || globalScrollStage === 2)) {
//             localScrollStage = globalScrollStage + 1;
//         } else {
//             if (getScrollTop() === 0 && e.deltaY < 0) {
//                 localScrollStage = globalScrollStage - 1;
//                 type = 'scroll';
//             }
//         }

//         if (localScrollStage < 1) {
//             localScrollStage =  1;
//         }
//         if (localScrollStage > 3) {
//             localScrollStage = 3;
//         }
    
//         if (getScrollTop() === 0 && (Date.now() - 200 > lastScrollEventTimeStamp)) {
//             trigger('change-to-section', {
//                 stage: localScrollStage,
//                 type: type
//             })
//         }
//         lastScrollEventTimeStamp = Date.now();
//     }


// };

// var preventDefaultForScrollKeys = function preventDefaultForScrollKeys(e) { 
//     if (keys[e.keyCode]) {
//         preventDefault(e, 'keypress');
//         return false;
//     }
// }

function scrollToTarget($target) {
    $('html,body').animate({
        scrollTop: $($target).offset().top
    },'slow');
}

// function trigger(type, eventData) {
//     $window.trigger({
//         type: type,
//         eventData: eventData
//     });
// }

// function init() {
//     var scrollTop = getScrollTop();

//     try {
//       window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
//         get: function () {
//             supportsPassive = true;
//             wheelOpt = supportsPassive ? { passive: false } : false
//         } 
//       }));
//     } catch(e) {}

//     initializeListeners();

//     $window.scroll(() => {
//         if ($window.width() <= INITIALIZE_ANIMATION_WIDTH) {
//             return;
//         }
//         if ((globalScrollStage === 1 || globalScrollStage === 2) && (getScrollTop() !== 0)) {
//             trigger('change-to-section', {
//                 stage: 3,
//                 type: 'startedHere'
//             });
//         }
//     })

//     $.each([ '.tela01' , '.tela02', '.tela03' ], function(index, value) {
//         $(`.sec01 .indicadores .botao-rolagem${value}`).click(function() {
//             trigger('change-to-section', {
//                 stage: index+1,
//                 type: 'button'
//             });
//         });
//     });

//     $('.sec01 .button-down').click((event) => {
//         trigger('next-section');
//     });

//     if (scrollTop === 0) {
//         trigger('change-to-section', {
//             stage: 1,
//             type: 'scroll'
//         });
//     } else {
//         trigger('change-to-section', {
//             stage: 3,
//             type: 'startedHere'
//         });
//     }
// }

// if ($window.width() <= INITIALIZE_ANIMATION_WIDTH) {
//     var hammer = new Hammer($('.sec01').get(0));
//     hammer
//         .on('swipeleft', () => {
//             trigger('change-to-section', {
//                 stage: 2,
//                 type: 'scroll'
//             });
//         });
//     hammer
//         .on('swiperight', () => {
//             trigger('change-to-section', {
//                 stage: 1,
//                 type: 'scroll'
//             });
//         });
// }

// $window.on('next-section', () => {
//     if (globalScrollStage === 1) {
//         trigger('change-to-section', {
//             stage: 2,
//             type: 'scroll'
//         });
//     } else if (globalScrollStage === 2) {
//         trigger('change-to-section', {
//             stage: 3,
//             type: 'button'
//         });
//     }
// });

// $window.on('change-to-section', ({ eventData }) => {
//     const $telas = $('.sec01 .telas');
//     const $indicadores = $('.sec01 .indicadores');
//     const $buttonDown = $('.sec01 .button-down');

//     globalScrollStage = eventData.stage;

//     $('.sec01 .indicadores .botao-rolagem').removeClass('active');
//     $('.sec01 .indicadores .botao-rolagem.tela0'+eventData.stage).addClass('active');
    
//     if ($window.width() <= INITIALIZE_ANIMATION_WIDTH) {
        
//         switch(eventData.stage) {
//             case 1: {
//                 $telas.find('.tela01').removeClass('d-none left-to-fade-anim').addClass('d-block');
//                 $telas.find('.tela02').removeClass('d-block').addClass('d-none');
//                 $('.sec01 .indicadores').removeClass('opacity-0');
//                 // Força as animações do texto aparecendo a acontecer
//                 $(window).trigger('scroll');
//             } break;
//             case 2: {
//                 $telas.find('.tela02').removeClass('d-none left-to-fade-anim').addClass('d-block');
//                 $telas.find('.tela01').removeClass('d-block').addClass('d-none');
//                 $('.sec01 .indicadores').removeClass('opacity-0');
//                 // Força as animações do texto aparecendo a acontecer
//                 $(window).trigger('scroll');
//             }break;
//             default: throw new Error('Etapa inválida mobile')
//         }

//         return;
//     }

//     switch(eventData.stage) {
//         case 1: {
//             t.bold_rate = 0.4;
//             colorParticles = COLOR.RED;
//             colorBackground = COLOR.BLACK;
//             $telas.find('.tela01').removeClass('d-none left-to-fade-anim').addClass('d-block');
//             $telas.find('.tela02').removeClass('d-block').addClass('d-none');
//             $indicadores.removeClass('t0203');
//             $('.sec01 .indicadores').removeClass('opacity-0');
//             $buttonDown
//                 .addClass('d-block')
//                 .removeClass('d-none')
//                 .find('img')
//                 .attr('src', $buttonDown.find('img').data('white'));
//             // Força as animações do texto aparecendo a acontecer
//             $(window).trigger('scroll');
//         } break;
//         case 2: {
//             t.bold_rate = 0.6;
//             colorParticles = COLOR.PURPLE;
//             colorBackground =   COLOR.WHITE;
//             $telas.find('.tela02').removeClass('d-none left-to-fade-anim').addClass('d-block');
//             $telas.find('.tela01').removeClass('d-block').addClass('d-none');
//             $indicadores.addClass('t0203');
//             $('.sec01 .indicadores').removeClass('opacity-0');
//             $buttonDown
//                 .addClass('d-block')
//                 .removeClass('d-none')
//                 .find('img')
//                 .attr('src', $buttonDown.find('img').data('black'));;
//             // Força as animações do texto aparecendo a acontecer
//             $(window).trigger('scroll');
//         }break;
//         case 3: {
//             t.bold_rate = 0.4;
//             colorParticles = COLOR.PURPLE;
//             colorBackground =   COLOR.WHITE;
//             $telas.find('.tela02').removeClass('d-none left-to-fade-anim').addClass('d-block');
//             $telas.find('.tela01').removeClass('d-block').addClass('d-none');
//             $indicadores.addClass('t0203');
//             setTimeout(() => $('.sec01 .indicadores').addClass('opacity-0'), 200);
//             $buttonDown
//                 .addClass('d-block')
//                 .removeClass('d-none')
//                 .find('img')
//                 .attr('src', $buttonDown.find('img').data('black'));;
//             $('.sec01 .button-down').addClass('d-none').removeClass('d-block');
//             if (eventData.type !== 'startedHere' && eventData.type !== 'scroll') {
//                 scrollToTarget('#sec02');
//             };
//         }break;
//         default: throw new Error('Etapa inválida')
//     }
// })

function initServicesAnimation() {
    /**
     * Lista de palavras da seção de serviço
     */
    const words = [
        'Consultoria', 'Upgrades', 'Network', 'Design Thinking', 'Implantação',
        'Desenvolvimento', 'Aceleradores', 'Hardware', 'IOT', 'FIORI', 'MII/MES',
        'Software Factory', 'SDN', 'Agile', 'HANA', 'Digital BPO', 'Data Analytics',
        'Waterfall', 'Self Solution', 'Migration', 'Suporte', 'S/4HANA', 'Financial Services',
        'Workmanager', 'AMS', 'Multicloud', 'HYBRIS', 'Cybersecurity', 'Mobile', 'Inovação',
        'SAP', 'Blockchain', 'Tecnologia', 'RPA', 'Squads', 'Servers'
    ];

    const getRandom = (howMany) => {
        const picked = [];

        if (howMany) while(howMany--) {
            picked.push(words[ ~~(words.length * Math.random()) ]);
        }

        return picked
    }

    const $text = $('.sec03 .servicos .text');
    const wordsPerLine = 20;

    for (let lines = 0; lines < 6; lines++) {
        let wordsForThisLine = getRandom(wordsPerLine);
        let $line = $('<div class="line">');

        if (lines % 2) {
            $line.addClass('pair');
        } else {
            $line.addClass('even');
        }
        for (let i = 0; i < wordsPerLine; i++) {
            let $span =  $('<div class="word">').text(wordsForThisLine[i]);
            if (i % 2) {
                $span.addClass('pair');
            } else {
                $span.addClass('even');
            }
            $line.append($span)
        }
        $text.append($line);
    }

    /**
     * Start listener hover da seção serviços
     */
    const $servicos = $('.sec03 .servicos .servico');
    
    // $servicos.on('mouseover focus', (event) => {
    //     $servicos.removeClass('active');
    //     $(event.currentTarget).addClass('active');
    // });

    $servicos
        .hover(function(event) {
            $(event.currentTarget).addClass('active');

        }, function(event) {
            $(event.currentTarget).removeClass('active');
        });

    $servicos.focus((event) => {
        $(event.currentTarget).addClass('active');
    });
    
    $servicos.blur((event) => {
        $(event.currentTarget).removeClass('active');
    });
};

function modalShow() {
    $(document.head.parentNode).addClass('scroll-hidden');
    $('.background-stories').addClass('modal-visible');
}

function modalHide() {
    $(document.head.parentNode).removeClass('scroll-hidden');
    $('.background-stories').removeClass('modal-visible')
}

function initSomosUmTime(num) {
    $('#membro01').click(function(event) {
        var $video = $('#storie01');
        var $storie = $('#st01');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro02').click(function(event) {
        var $video = $('#storie02');
        var $storie = $('#st02');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro03').click(function(event) {
        var $video = $('#storie03');
        var $storie = $('#st03');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro04').click(function(event) {
        var $video = $('#storie04');
        var $storie = $('#st04');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro05').click(function(event) {
        var $video = $('#storie05');
        var $storie = $('#st05');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro06').click(function(event) {
        var $video = $('#storie06');
        var $storie = $('#st06');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro07').click(function(event) {
        var $video = $('#storie07');
        var $storie = $('#st07');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('#membro08').click(function(event) {
        var $video = $('#storie08');
        var $storie = $('#st08');
        $('#mask-home').addClass('d-block');
      
        modalShow()
        $storie.addClass('normalSize');

        setTimeout(function() {
            $video.get(0).currentTime = 0;
            $video.get(0).play();
        }, +$storie.css('transition-duration').replace('s', '')||200);
    });
    $('.background-stories .btn-close').click(function(event) {
        var $video = $('#storie01');
        var $video2 = $('#storie02');
        var $video3 = $('#storie03');
        var $video4 = $('#storie04');
        var $video5 = $('#storie05');
        var $video6 = $('#storie06');
        var $video7 = $('#storie07');
        var $video8 = $('#storie08');
        var $storie = $('.background-stories .storie');
        $storie.removeClass('normalSize');
        $('#mask-home').removeClass('d-block');
        $video.get(0).pause();
        $video2.get(0).pause();
        $video3.get(0).pause();
        $video4.get(0).pause();
        $video5.get(0).pause();
        $video6.get(0).pause();
        $video7.get(0).pause();
        $video8.get(0).pause();
        modalHide();
    });
}

/**
 * Animação da home
 */
// window.addEventListener('load', init);

/**
 * Animação 
 */
window.addEventListener('load', initServicesAnimation);

/**
 * Somos um time 
 */
window.addEventListener('load', initSomosUmTime);